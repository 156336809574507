import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { deleteRebateCredit } from '../../utils/creditForecast';

export default function RebateCreditWindow({data, set}) {

    function handleDelete(e, listname) {
        e.stopPropagation();
        deleteRebateCredit(listname, data.creditlist, set);
    };

    return (
        <>  
            <div className='border p-10 h-[650px] overflow-y-auto flex flex-col gap-2 rounded'>
                {data.creditlist&&data.creditlist.map(creditDetails => (
                    <div className='border py-3 px-4 flex justify-between'>
                        {creditDetails.listname}
                        <button type='button' onClick={e => handleDelete(e, creditDetails.listname)}>
                            <FontAwesomeIcon className={`text-2xl text-primary-red hover:text-red-700`} icon={faXmarkCircle}/> 
                        </button>
                    </div>
                ))}
            </div>
        </>
    )
}