import React, { useState } from 'react';
import { globalChangeHandler } from '../../utils/globalHandlers';
import { createRebateCredit } from '../../utils/creditForecast';

export default function CreateRebateCreditWindow({set, change}) {

    const labelStyles = "flex flex-col mt-3 capitalize";
    const inputStyles = "border rounded-full px-6 py-2 mt-1";

    const [ fields, setFields ] = useState({
        listname : '',
        drugname : '',
        credit_type : '',
        ndc : '',
        startdate : '',
        mag : ''
    });

    function handleSubmit(e) {
        e.preventDefault();
        createRebateCredit(fields, set, change);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className='border p-6'>
                <label htmlFor='listname' className={labelStyles}>listname
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.listname} name='listname' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='drugname' className={labelStyles}>drugname
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.drugname} name='drugname' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='credit_type' className={labelStyles}>credit type
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.credit_type} name='credit_type' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='ndc' className={labelStyles}>NDC
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.ndc} name='ndc' type='text' className={inputStyles} required />
                </label>
                <label htmlFor='startdate' className={labelStyles}> startdate
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.startdate} name='startdate' type='date' className={inputStyles} required />
                </label>
                <label htmlFor='mag' className={labelStyles}>magnitude
                    <input onChange={e => globalChangeHandler(e, null, setFields)} value={fields.mag} name='mag' type='number' min={0} max={1} step={0.001} className={inputStyles} required />
                </label>
                <div className='flex justify-between'>
                    <button type='button' onClick={() => change('list')} className='mt-10 border py-3 px-12 rounded-full'>prev</button>
                    <button type='submit' className='mt-10 border py-3 px-12 rounded-full'>create</button>
                </div>
            </form>
        </>
    );
};