import { extractFromLocal } from "./loadSave";

export async function getCreditlists(set) {
    try {
        const data = await fetch(`/api/get-creditlists/`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                creditlist: results
            }));
        }
    } catch(err) {
        return err;
    };
};

export async function forecastCredits(listname, scenario, casenumber, set, load) {
    load(true);
    try {
        const data = await fetch(`/api/forecast_credits?listname=${listname}&scenario=${scenario}&casenumber=${casenumber}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();
        
        if (data.ok) {
            set(data => ({
                ...data,
                forecastingData: {
                    ...data.forecastingData,
                    [scenario]: results
                }
            }));
            load(false);
        }
    } catch(err) {
        load(false);
        return err;
    };
};

export async function createRebateCredit(payload, set, change) {
    try {
        const data = await fetch(`/api/create-rebate-credit/`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            },
            body: JSON.stringify(payload)
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                creditlist: [...data.creditlist, {listname: results.listname} ]
            }));
            change("list");
        }
    } catch(err) {
        return err;
    };
};


export async function deleteRebateCredit(creditId, array, set) {
    try {
        const data = await fetch(`/api/delete-rebate-credit/${creditId}`, {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();

        if (data.ok) {
            let current = [ ...array ];
            set(data => ({
                ...data,
                creditlist: current.filter(credit => credit.listname !== creditId)
            }));
            console.log(results)
        };
    } catch(err) {
        return err;
    };
};


export async function updateRebateCredit(creditId, set) {
    try {
        const data = await fetch(`/api/update-rebate-credit/${creditId}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json', 
                Authorization: `Bearer ${extractFromLocal()?.accessToken}`
            }
        });
        const results = await data.json();

        if (data.ok) {
            set(data => ({
                ...data,
                creditlist: results
            }));
        }
    } catch(err) {
        return err;
    };
};

