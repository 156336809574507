import React, { useEffect, useMemo } from 'react';
import Navbar from '../Navbar/Navbar';
import HomePage from '../../pages/HomePage';
import ImportClaim from '../ImportWindow/ImportClaim';
import { UseContextClues } from '../../context/ContextClues';
import { UseUserContext } from '../../context/UserContext';
import { UseToggleContext } from '../../context/ToggleContexts';
import ChannelDesignation from '../ChannelDesignation/ChannelDesignation';
import { checkUser } from '../../utils/accounts';
import { globalBoolHandler, handleGlobalLogout, produceDate } from '../../utils/globalHandlers';
import LoadSaved from '../LoadSave/LoadSaved';
import { extractFromLocal } from '../../utils/loadSave';
import AuxLogin from '../AuxLogin/AuxLogin';
import ImportFormulary from '../ImportWindow/ImportFormulary';
import MobileBlocker from '../Navbar/MobileBlocker';
import Pipeline from '../Pipeline/Pipeline';
import UserDefinedLists from '../UserDefinedLists/UserDefinedLists';
import TimeOut from '../TimeOut/TimeOut';
import OrgSettings from '../OrgSettings/OrgSettings';
import MyFiles from '../MyFiles/MyFiles';
import TableManagement from '../TableManagement/TableManagement';
import SaveHelper from '../LoadSave/SaveHelper';
import VendorPricingExtract from '../TableManagement/VendorPricingExtract';
import MedispanAdmin from '../MedispanAdmin/MedispanAdmin';
import RebateCreditConsole from '../RebateCreditConsole/RebateCreditConsole';

export default function FullSection(){

    const context = UseContextClues();
    const account = UseUserContext();
    const toggler = UseToggleContext();
    
    // let count = 0
    const startTime = new Date(extractFromLocal()?.loggedInAt)

    useMemo(() => {
      async function fetchUser() {
        let user = await checkUser(account.setUser, context, toggler);

        if (!user||user.detail) {
            handleGlobalLogout(account, context, toggler);
            clearInterval(window.idleIntervalTimer);
        };
      };

      function checkOrWarnCurrentUser() {
        const currentTime = new Date();
        // console.log((currentTime-startTime)/60000)
        if (startTime) {
          if (((currentTime-startTime) >= 86400000000)) {
            fetchUser();
            // toggler.toggleTime(false);
            if (toggler.toggle.showTime) {
              globalBoolHandler("showTime", toggler.toggle, toggler.setToggle, false);
            };
    
          } else if (((currentTime-startTime) < 86400000000)&&((currentTime-startTime) > 84600000000)) {
            // toggler.toggleTime(true);
            if (!toggler.toggle.showTime) {
              globalBoolHandler("showTime", toggler.toggle, toggler.setToggle, true);
            };
              
          };
        };
      };

      if (startTime) {
        checkOrWarnCurrentUser()
        clearInterval(window.idleIntervalTimer)
        window.idleIntervalTimer = setInterval(() => {
          // count+=1
          checkOrWarnCurrentUser()
        }, [1000])  
      } 
      return () => clearInterval(window.idleIntervalTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startTime]);



    useEffect(() => {
      if (!context.settings.modelSettings.channelDesignation ||
        !context.settings.modelSettings.daysBreak ||
        !context.settings.modelSettings.modelSpecialtyList ||
        !context.settings.modelSettings.lDDList ||
        !context.settings.modelSettings.priceDate
        ) {
        
        context.setSettings(data => ({
          ...data,
          modelSettings: {
            channelDesignation: context.settings.modelSettings.channelDesignation?context.settings.modelSettings.channelDesignation:context.models.modelList?.channel_mapping[0],
            daysBreak: context.settings.modelSettings.daysBreak?context.settings.modelSettings.daysBreak:context.models.modelList?.channel_daysbreak,
            modelSpecialtyList: context.settings.modelSettings.modelSpecialtyList?context.settings.modelSettings.modelSpecialtyList:context.models.modelList?.specialty_lists[0],
            lDDList: context.settings.modelSettings.lDDList?context.settings.modelSettings.lDDList:context.models.modelList?.ldd_lists[0],
            priceDate: context.settings.modelSettings.priceDate?context.settings.modelSettings.priceDate:produceDate(),
          }
        }));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account.user?.userData?.username, account.loggedIn, context.casenumber, context.models.modelList?.channel_mapping.length, context.models.modelList?.claims_tables.length, context.models.modelList?.ldd_lists.length]);

    useEffect(() => {
      // if (context.settings.modelSettings.priceDate) {
          context.setExclusionSettings(data => ({
              ...data,
              priceDate:context.settings.modelSettings.priceDate,
          }));
      // };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.settings.modelSettings.priceDate]);

    // console.log('fullsection context', context);
    // console.log('fullsection account', account);
    // console.log('fullsection context', toggler);

    return (
      <>
          <MobileBlocker />
          <Navbar /> 
          <HomePage />
          {toggler.toggle?.importWindow&&<ImportClaim {...{setPageData: null, mainPageData: true}}  />} 
          {toggler.toggle?.importFormulary&&<ImportFormulary  />} 
          {(toggler.toggle?.saveLoad||toggler.toggle?.loadSave||!context.casenumber)&&(toggler.isLoading)&&<LoadSaved />}
          {toggler.toggle?.auxLogin&&<AuxLogin />}
          {toggler.toggle?.channelDesignationPanel&&<ChannelDesignation  />} 
          {toggler.toggle?.saveMemo&&<SaveHelper />}
          {toggler.toggle?.pipelineEvents&&<Pipeline />}
          {toggler.toggle?.myFiles&&<MyFiles />}
          {toggler.toggle?.userDefinedLists&&<UserDefinedLists />}
          {toggler.toggle?.tableManagement&&<TableManagement />}
          {toggler.toggle?.createVendorPricingExtract&&<VendorPricingExtract />}
          {(account.user?.userData?.user_type==="superadmin")&&
            <>
                {toggler.toggle?.medispanAdmin&&<MedispanAdmin />}
            </>
          }
          {(account.user?.userData?.user_type==="client_mgr")&&
            <>
                {toggler.toggle?.rebateCredit&&<RebateCreditConsole />}
            </>
          }
          {toggler.toggle?.orgSettings&&account.user?.userData.user_type==="client_mgr"&&<OrgSettings />}
          {toggler.toggle?.timeOut&&toggler.userActivity === 'Warning'&&<TimeOut {...{}} />}
      </>
    );
};