import React, { useEffect, useState } from 'react';
import PopUpSection from '../Section/PopUpSection';
import { globalBoolHandler } from '../../utils/globalHandlers';
import { UseToggleContext } from '../../context/ToggleContexts';
import AssumptionTable from '../Tables/AssumptionTable';
import { convertToKeyValue, getOrgAssumptions, setOrgAssumptions } from '../../utils/assumptions';
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';

export default function OrgSettings() {

    const toggler = UseToggleContext();

    const [ payload, load ] = useState({});
    const [ bool, setBool ] = useState();
    const [ updated, update ] = useState([]);

    useEffect(() => {
        update(convertToKeyValue(payload));
    }, [payload]);

    useEffect(() => {
        getOrgAssumptions(load);
    }, []);

    function changeHandler(e) {
        const { name, value, id, checked } =  e.target;
        let array = []

        if (id === "assumptions-toggle") {
            updated.forEach(ele => {
                if (ele.assumption === 'apply improvement year 1') {
                    array.push({
                        assumption: ele.assumption,
                        value: checked?1:0
                    });
                } else {
                    array.push(ele);
                };
            });
            update(array)
        } else {
            updated.forEach(ele => {
                if (ele.assumption === name) {
                    array.push({
                        assumption: ele.assumption,
                        value: +value
                    })
                } else {
                    array.push(ele);
                };
            });
            update(array)
        }
    };

    function handleSubmit(e) {
        e.preventDefault();
        setOrgAssumptions({ assumptions : updated }, load);
    };

    function handleEscape(){
        globalBoolHandler("orgSettings", toggler.toggle, toggler.setToggle, false);
    };
    HandleKeydownEscape(handleEscape);

    return (
        <>
            <PopUpSection popone={"absolute top-0 z-[200] h-[100vh] w-[100vw] bg-gray-500 opacity-[0.6]"} >
               <form onSubmit={handleSubmit} className=' bg-primary-dark rounded-xl w-[670px] h-[500px] flex mx-auto justify-center flex-col px-8 overflow-y-auto'>
                    <div className='text-white mb-2 tracking-widest uppercase'>
                        Set organization assumptions
                    </div>     
                    <div className='bg-white rounded overflow-y-auto w-[600px] h-[350px] p-2'>
                            {updated.length>0?<AssumptionTable {...{ dataContext: payload, bool, setBool, changeHandler: changeHandler, excludeHedge: true }} />:<div className='flex h-full items-center justify-center'><l-leapfrog color="black"></l-leapfrog></div>}
                    </div>
                   
                    <div>
                        <button type='button' className='text-white border rounded-lg  mt-4 py-2 w-40 bg-light-blue hover:bg-inherit' onClick={() => globalBoolHandler("orgSettings", toggler.toggle, toggler.setToggle, false)}>
                            Cancel
                        </button>
                        <button type='button' className='text-white border rounded-lg border-white mt-4 py-2 w-40  hover:bg-light-blue float-right'>
                            Save
                        </button>
                    </div>
                </form>
            </PopUpSection>
        </>
    )
};