import React, { useEffect, useRef, useState } from "react";
import { UseContextClues } from '../../context/ContextClues';
import { UseToggleContext } from '../../context/ToggleContexts';
import Loader from "../Loader/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation, faFilePdf, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { downloadToPDFGlobal } from "../../utils/download";
import FormButton from "../Buttons/FormButton";
import { handleGlobalToggle } from "../../utils/globalHandlers";
import VolumeComparisonTable from "../Tables/VolumeComparisonTable";
import PopUpSection from "../Section/PopUpSection";
import ReabateOp from "../SVG/RebateOp";
import Logo259 from "../SVG/Logo259";
import DataMetricsDynamic from "../Tables/DataMetricsDynamic";
import HandleKeydownEscape from '../../hooks/HandleKeydownEscape';

export default function KpiPanel (){
    
    const dataMetrics = useRef(null);
    
    const context = UseContextClues();
    const toggler = UseToggleContext();
    const [ volumeComparison, setVCWindow ] = useState(false);

    useEffect(() => {
        if (context.mainData?.volume_comparison?.comparison) {
            setVCWindow(true);
        } else setVCWindow(false)
    }, [context.mainData?.volume_comparison])

    const tableHeader = "text-sm font-bold w-full";
    const tableData = "font-light";    

    function handleEscape(){
        setVCWindow(false)
    };
    HandleKeydownEscape(handleEscape);

    return (
        <div style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-100)*.6+"px"}:{height: (toggler.pageHeight-98)+"px"}} className={`relative overflow-y-auto ${!toggler.toggle?.helpWindow&&"row-span-2"} ` }>
            {context.mainData?<div className="p-4 h-auto">
                <div className="grid grid-cols-[25%,75%]  whitespace-nowrap">
                    <div className="font-medium text-xl flex flex-col pb-4 ">
                        <span className="">Data Metrics</span>
                        <div className={tableHeader}>Status: 
                            <span className={tableData}>
                                <span className="ml-3">
                                    {context.mainData.success ? 
                                        <FontAwesomeIcon className='text-lg mr-4 text-primary-green' icon={faCircleCheck}/> 
                                        : 
                                        <FontAwesomeIcon className='text-lg mr-4 text-primary-red' icon={faCircleExclamation}/>
                                    }
                                </span>
                            </span>
                        </div>
                        <div>
                            <span className="text-xs font-medium">  
                                Controls:
                            </span>
                            <button aria-label="download data metrics by pdf" type="button" className="pl-4 text-left text-xl hover:text-hover-blue text-primary-blue" onClick={() => downloadToPDFGlobal(dataMetrics, "Data_Metrics", null, null,null,null ,null, toggler.setStatus)}>
                                <FontAwesomeIcon className="" icon={faFilePdf} />
                            </button>
                            <button aria-label="toggle data metrics and map viewer" type="button" className="pl-4 text-left text-xl hover:text-hover-blue text-primary-blue" onClick={() => handleGlobalToggle(toggler.setToggle, "countiesMap", toggler.toggle.countiesMap)}>
                                <FontAwesomeIcon className="" icon={faMapLocationDot} />
                            </button>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center mt-4 mb-1 text-xs">
                            <div className="w-full text-center font-bold uppercase rounded-l-full " >very low</div>
                            <div className="w-full text-center font-bold uppercase  " >low</div>
                            <div className="w-full text-center font-bold uppercase  " >normal</div>
                            <div className="w-full text-center font-bold uppercase  " >high</div>
                            <div className="w-full text-center font-bold uppercase rounded-r-full " >very high</div>
                        </div>
                        <div className="flex justify-center mb-4 mx-2">
                            <div className="w-full h-4 rounded-l-full bg-hover-blue" ></div>
                            <div className="w-full h-4  bg-light-blue" ></div>
                            <div className="w-full h-4  bg-light-green" ></div>
                            <div className="w-full h-4  bg-light-red" ></div>
                            <div className="w-full h-4 rounded-r-full bg-primary-red" ></div>
                        </div>
                    </div>
                </div>
                    {context.mainData.success&&<div ref={dataMetrics} style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-325)*.6+"px"}:{height:toggler.pageHeight-235}} className="overflow-y-auto pb-4 px-1">
                        <DataMetricsDynamic {...{setVCWindow, }} />
                    </div>
                }
                {!context.mainData.success&&<div>
                    Failed to load data due to the following issues: {context.mainData.error}
                </div>}
            </div>:
            <div className="p-4">
                <div className='border-2 relative rounded' style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-160)*.6 +"px"}:{height: (toggler.pageHeight-160)}} >
                    <div style={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-160)*.6 +"px"}:{height: (toggler.pageHeight-160)}} className="w-full rounded opacity-[0.1] flex flex-col justify-center absolute top-0 bottom-0 cover bg-primary-dark m-auto">
                        <div className="flex items-center justify-center">
                            {window.location.href.toLowerCase().includes("app.rebateoptimizer.com")?<ReabateOp switchColors={"#cecece"} scale={"scale(0.5)"} className={""}/>:<Logo259 />}
                        </div>
                    </div>
                    <Loader loading={toggler.loader === "kpi"} loadStyle={toggler.toggle?.helpWindow?{height: (toggler.pageHeight-130)*.6 +"px"}:{height: (toggler.pageHeight-150)}} textContent="Please input claims information to retrieve KPI data " addClass={'font-extrabold'}/>
                </div>
            </div>
            }
            {volumeComparison&&<>
                <PopUpSection 
                    popone={'absolute opacity-[0.8] bg-primary-dark top-0 bottom-0 left-0 right-0 flex items-center flex-col justify-center overflow-y-auto'}
                    poptwo={'bg-primary-light p-6 opacity-[1] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[350px] rounded'}
                    >
                    <VolumeComparisonTable />
                    <FormButton buttonText={"OK"} onClick={() => setVCWindow(false)} />
                </PopUpSection>
            </>}
        </div>
    )
}