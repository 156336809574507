import React, { useEffect, useState } from 'react';
import { forecastCredits, getCreditlists } from '../../utils/creditForecast';
import { UseContextClues } from '../../context/ContextClues';
import { globalChangeHandler } from '../../utils/globalHandlers';
import CreditForecastingTable from '../Tables/CreditForecastingTable';
import TailChaseLoader from '../Loader/TailChaseLoader';

export default function CreditForecast({reference}) {

    const context = UseContextClues();

    const [ data, set ] = useState({
        listname: '',
        scenario: 'standard',
        creditlist: [],
        // forcasting: null
    });
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        getCreditlists(set);
    }, []);

    function forecast(e) {
        e.preventDefault();
        forecastCredits(data.listname, data.scenario, context.casenumber, context.setPricingLens, setLoading);
    };

    const labelStyles = 'flex flex-col';
    const selectStyles = 'py-3 px-2 border rounded mt-1';

    return (
        <>
            <div className='min-w-[800px]'>
                <div className='text-xl pb-3'>
                    Credit Forecasting
                </div>
                <form onSubmit={forecast} className='flex mb-10'>
                    <div className='w-full border p-4 mr-2'>
                        <label htmlFor='listname' className={labelStyles}>Listname
                            <select required className={selectStyles} onChange={e => globalChangeHandler(e, null, set)} name='listname'>
                                <option value={''}>
                                    -- choose one --
                                </option>
                                {data.creditlist.map(list => (
                                    <option value={list.listname}>
                                        {list.listname}
                                    </option>
                                ))}
                            </select>
                        </label>
                        <label htmlFor='scenario' className={labelStyles}>Scenario selection
                            <select required className={selectStyles}  onChange={e => globalChangeHandler(e, null, set)} name='scenario'>
                                <option value={'standard'}>
                                    standard
                                </option>
                                <option value={'target'}>
                                    target
                                </option>
                            </select>
                        </label>
                    </div>
                    <button className='border bg-light-blue hover:bg-hover-blue text-white hover:shadow-xl rounded-lg'>
                        <div className='rotate-90'>Forecast</div>
                    </button>
                </form>
                {loading?<div className='flex items-center justify-center pt-40'><TailChaseLoader size={200} /></div>:((context.pricingLens.forecastingData?.standard||context.pricingLens.forecastingData?.target)&&context.pricingLens.forecastingData[data.scenario]&&<CreditForecastingTable {...{data: context.pricingLens.forecastingData[data.scenario].forecasted_credits, reference}} />)}
            </div>
        </>
    );
};